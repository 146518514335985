var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row books"
  }, [_c('div', {
    staticClass: "col-md-12 mt-1"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm.isError ? _c('b-row', {
    staticClass: "justify-content-center mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" Opps .. Something is wrong ")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-sm-4"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "searcg",
      "id": "",
      "placeholder": "type book name or vendor name..."
    },
    on: {
      "input": _vm.searchBook
    }
  }), _vm.typing ? _c('span', [_c('em', [_vm._v(" Mencari .. ")])]) : _vm._e()]), _c('div', {
    staticClass: "col-md-2"
  }, [_c('b-form-select', {
    attrs: {
      "id": "currency",
      "options": _vm.filterDate,
      "required": ""
    },
    on: {
      "change": _vm.onFilterChange
    },
    model: {
      value: _vm.sortDate,
      callback: function ($$v) {
        _vm.sortDate = $$v;
      },
      expression: "sortDate"
    }
  })], 1), _c('div', {
    staticClass: "col-md-2"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.updateOfsBulk
    }
  }, [_c('i', {
    staticClass: "fa fa-send"
  }), _vm._v(" Update OFS ")])]), _c('div', {
    staticClass: "col-md-2 h5"
  }, [_c('b-form-checkbox', {
    attrs: {
      "id": "select-all",
      "name": "check-button",
      "size": "lg",
      "switch": ""
    },
    on: {
      "change": _vm.selectAll
    },
    model: {
      value: _vm.allSelected,
      callback: function ($$v) {
        _vm.allSelected = $$v;
      },
      expression: "allSelected"
    }
  }, [_vm._v(" " + _vm._s(_vm.checkedAll) + " ")])], 1)]), _c('div', {
    staticClass: "row justify-content-end pb-1"
  }, [_c('button', {
    staticClass: "btn btn-sm btn-dark",
    on: {
      "click": _vm.actionGetEbooks
    }
  }, [_c('i', {
    staticClass: "fa fa-refresh"
  })]), _c('label', {
    staticClass: "col-sm-auto",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Count ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.formatPriceBasic(_vm.totalRows)))])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "",
      "show-empty": "",
      "busy": _vm.isLoading,
      "fields": _vm.fields,
      "hover": "",
      "items": _vm.ofscatalog
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "35px"
          }
        }, [row.item.is_active ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.itemsID,
            expression: "itemsID"
          }],
          staticClass: "px-2",
          attrs: {
            "type": "checkbox"
          },
          domProps: {
            "value": row.item.digitalid,
            "checked": Array.isArray(_vm.itemsID) ? _vm._i(_vm.itemsID, row.item.digitalid) > -1 : _vm.itemsID
          },
          on: {
            "change": [function ($event) {
              var $$a = _vm.itemsID,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = row.item.digitalid,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && (_vm.itemsID = $$a.concat([$$v]));
                } else {
                  $$i > -1 && (_vm.itemsID = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.itemsID = $$c;
              }
            }, _vm.select]
          }
        }) : _vm._e(), _vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")])];
      }
    }, {
      key: "cell(title)",
      fn: function (row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: _vm.DetailItems.name,
              params: {
                id: row.item.digitalid
              }
            }
          }
        }, [_vm._v(" " + _vm._s(row.item ? row.item.name : '') + " ")])];
      }
    }, {
      key: "cell(printed_price)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.price, 'IDR')) + " ")])];
      }
    }, {
      key: "cell(size)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.convertMegaBite(row.item.file_size)) + " ")])];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(row.item.created)) + " ")])];
      }
    }, {
      key: "cell(info)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(row.item.modified)) + " ")])];
      }
    }, {
      key: "cell(detail)",
      fn: function (row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: _vm.DetailCatalogOfsPath.name,
              params: {
                id: row.item.digitalid
              }
            }
          }
        }, [_c('button', {
          staticClass: "btn btn-sm mt-2",
          attrs: {
            "type": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-eye"
        })])])];
      }
    }, {
      key: "cell(action)",
      fn: function (row) {
        return [row.item.is_active ? _c('b-dropdown', {
          staticClass: "m-2",
          attrs: {
            "split": "",
            "split-variant": "outline-primary",
            "variant": "primary",
            "text": "Actions"
          }
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.actionSendOfs(row.item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-send"
        }), _vm._v(" Update Ofs ")])], 1) : _vm._e()];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-row', {
    staticClass: "justify-content-start m-2 p-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "col-sm-2",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Search Book "), _c('br')]);
}]

export { render, staticRenderFns }