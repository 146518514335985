<template>
  <div class="row books">
    <div class="col-md-12 mt-1">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row">
            <label for="" class="col-sm-2"> Search Book <br /> </label>
            <div class="col-sm-4">
              <input
                type="text"
                name="searcg"
                id=""
                @input="searchBook"
                class="form-control"
                placeholder="type book name or vendor name..."
              />
              <span v-if="typing"> <em> Mencari .. </em> </span>
            </div>
            <div class="col-md-2">
              <b-form-select
                id="currency"
                v-model="sortDate"
                :options="filterDate"
                required
                @change="onFilterChange"
              ></b-form-select>
            </div>
            <div class="col-md-2">
              <button class="btn btn-primary" @click="updateOfsBulk">
                <i class="fa fa-send"></i>
                Update OFS
              </button>
            </div>
            <div class="col-md-2 h5">
              <b-form-checkbox
                id="select-all"
                v-model="allSelected"
                @change="selectAll"
                name="check-button"
                size="lg"
                switch
              >
                {{ checkedAll }}
              </b-form-checkbox>
            </div>
          </div>
          <div class="row justify-content-end pb-1">
            <button class="btn btn-sm btn-dark" @click="actionGetEbooks">
              <i class="fa fa-refresh"></i>
            </button>
            <label for="" class="col-sm-auto">Count </label>
            <strong class="col-sm-auto">{{
              formatPriceBasic(totalRows)
            }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            responsive
            show-empty
            :busy="isLoading"
            :fields="fields"
            hover
            :items="ofscatalog"
          >
            <template #cell(no)="row">
              <div style="width:35px;">
                <input
                  type="checkbox"
                  v-if="row.item.is_active"
                  class="px-2"
                  v-model="itemsID"
                  @change="select"
                  :value="row.item.digitalid"
                />
                {{ (currentPage - 1) * perPage + (row.index + 1) }}
              </div>
            </template>
            <!-- <template #cell(checkbox)="row">
            </template> -->
            <template #cell(title)="row">
              <router-link
                :to="{
                  name: DetailItems.name,
                  params: { id: row.item.digitalid },
                }"
              >
                {{ row.item ? row.item.name : '' }}
              </router-link>
            </template>
            <template #cell(printed_price)="row">
              <div class="text-right">
                {{ formatPrice(row.item.price, 'IDR') }}
              </div>
            </template>
            <template #cell(size)="row">
              <div class="text-right">
                {{ convertMegaBite(row.item.file_size) }}
              </div>
            </template>
            <template #cell(created)="row">
              <div style="width:250px;">
                {{ formatDate(row.item.created) }}
              </div>
            </template>
            <template #cell(info)="row">
              <div style="width:250px;">
                {{ formatDate(row.item.modified) }}
              </div>
            </template>
            <template #cell(detail)="row">
              <router-link
                :to="{
                  name: DetailCatalogOfsPath.name,
                  params: { id: row.item.digitalid },
                }"
              >
                <button type="button" class="btn btn-sm mt-2">
                  <i class="fa fa-eye"></i></button
              ></router-link>
            </template>
            <template #cell(action)="row">
              <b-dropdown
                v-if="row.item.is_active"
                split
                split-variant="outline-primary"
                variant="primary"
                text="Actions"
                class="m-2"
              >
                <b-dropdown-item
                  variant="primary"
                  @click="actionSendOfs(row.item)"
                >
                  <i class="fa fa-send"></i>
                  Update Ofs
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start m-2 p-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { DetailItems } from '../../router/content';
import { DetailCatalogOfsPath } from '../../router/monitoring';
export default {
  name: 'ofscatalog',
  data() {
    return {
      DetailCatalogOfsPath,
      currentPage: 1,
      perPage: 100,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      typing: '',
      debounce: null,
      message: null,
      fields: [
        {
          key: 'no',
          label: 'No',
          thStyle: { width: '60px' },
          tdStyle: { width: '60px' },
        },
        // {
        //   key: 'checkbox',
        //   label: '',
        //   thStyle: { width: '60px' },
        // },
        {
          key: 'title',
          label: 'Name',
        },
        {
          key: 'printed_price',
          label: 'Price',
          thClass: 'text-right',
        },
        { key: 'digital_offer_id', label: 'Offer ID' },
        { key: 'ax_id', label: 'ID AX' },
        { key: 'vendor', label: 'Vendor' },
        {
          key: 'created',
          label: 'Created',
          thClass: 'max-width-200',
        },
        { key: 'info', label: 'Modified', thClass: 'max-width-200' },
        { key: 'detail', label: 'Detail' },
        { key: 'action', label: 'Action' },
      ],
      DetailItems,
      sortDate: 'DESC',
      filterDate: [
        { value: 'DESC', text: 'DESC' },
        { value: 'ASC', text: 'ASC' },
      ],
      checkedAll: 'Select All',
      allSelected: false,
      itemsID: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Catalog OFS | Content Management System Ebooks Gramedia.com';
      },
    },
    currentPage: function() {
      this.actionGetEbooks();
    },
  },
  computed: {
    // ...mapGetters("ofscatalog", ["ofscatalog"]),
    ...mapState({
      isLoading: (state) => state.ofscatalog.isLoading,
      isError: (state) => state.ofscatalog.isError,
      totalRows: (state) => state.ofscatalog.totalRows,
      ofscatalog: (state) => state.ofscatalog.items,
    }),
  },
  mounted() {
    this.actionGetEbooks();
  },
  methods: {
    ...mapActions('ofscatalog', ['fetchEbooks']),
    ...mapActions('ofscatalog', [
      'searchBooks',
      'fetchEbookById',
      'sendItem',
      'updateItem',
    ]),
    actionGetEbooks() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
        sort: this.sortDate,
      };
      this.fetchEbooks(payload);
    },

    formatPrice(value, currency) {
      if (value) {
        let formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return '0';
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    searchBook(event) {
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        if (this.message.length > 0) {
          this.searchBooks({
            q: event.target.value,
            page: 1,
            limit: this.perPage,
            sort: this.sortDate,
          });
        } else {
          const payload = {
            page: this.currentPage,
            limit: this.perPage,
            sort: this.sortDate,
          };
          this.fetchEbooks(payload);
        }
      }, 800);
    },
    formatDate(date) {
      return new Date(date).toString();
    },
    convertMegaBite(size) {
      if (size) {
        return (size / 1024 / 1024).toFixed(2) + ' MB';
      }
      return '0';
    },

    actionLoadingPopUp() {
      this.$swal({
        title: 'Loading',
        text: 'Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        didOpen: () => {},
      });
    },
    async actionSendOfs(item) {
      this.actionLoadingPopUp();
      try {
        let payload = {
          id: parseInt(item.digitalid, 10),
        };
        await this.sendItem(payload);
        this.$swal({
          toast: 'true',
          title: 'Success',
          text: 'Item has been sent, may take a few minutes to be displayed',
          icon: 'success',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } catch (error) {
        console.log(error);
        this.$swal({
          toast: 'true',
          title: 'Error',
          text: 'Item has not been sent to OFS Catalog',
          icon: 'error',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    },

    alertMsg(type, msg) {
      this.$swal({
        toast: 'true',
        title: type,
        text: msg,
        icon: type.toLowerCase(),
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    },

    updateOfsBulk() {
      this.actionLoadingPopUp();
      if (this.itemsID.length < 1) {
        this.alertMsg('Error', 'No item checked');
      } else {
        try {
          const payload = {
            id: this.itemsID,
          };
          this.updateItem(payload)
            .then(() => {
              this.alertMsg(
                'Success',
                'Item has been updated, may take a few minutes to be displayed',
              );
              this.itemsID = [];
              this.checkedAll = 'Select All';
              this.allSelected = false;
            })
            .catch((e) => {
              const errors = e.response.data.errors;
              if (e.response.status == 422) {
                errors.map((val) => {
                  this.alertMsg('Error', val.msg);
                });
              } else {
                this.alertMsg('Error', 'Ops. Something wrong !');
              }
            });
        } catch (error) {
          console.log(error);
          this.alertMsg('Error', 'Item has not been sent to OFS Catalog');
        }
      }
    },

    onFilterChange() {
      const payload = {
        page: this.currentPage,
        limit: this.perPage,
        sort: this.sortDate,
      };
      this.fetchEbooks(payload);
    },

    selectAll() {
      this.itemsID = [];

      if (this.allSelected) {
        for (let item in this.ofscatalog) {
          if (this.ofscatalog[item].is_active) {
            this.itemsID.push(this.ofscatalog[item].digitalid);
          }
        }
        this.checkedAll = `${this.itemsID.length} Selected`;
      } else {
        this.checkedAll = 'Select All';
      }
    },
    select() {
      this.allSelected = false;

      if (this.itemsID.length) {
        this.checkedAll = `${this.itemsID.length} Selected`;
      } else {
        this.checkedAll = 'Select All';
      }
    },
  },
};
</script>

<style lang="css" scoped>
.books {
  font-family: 'Avenir Next', 'effra', Helvetica, Arial, sans-serif;
}
.max-width-200 {
  max-width: 200%;
}
</style>
